import {
  DndContext,
  type DragEndEvent,
  DragOverlay,
  type DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import React from 'react';

import { type Guess, type OptionKey } from '../../../../puzzle/puzzle';
import { findBy, findByOrThrow } from '../../../lib/array';
import { useRefHeight } from '../../useRefHeight';
import { OptionTile, OptionTileDummy } from './OptionLine';
import { usePuzzleContext } from './PuzzleContext';
import { useSortStrategy } from './sort-strategy';

interface GuessComposerProps {
  onChange(guess: Guess): void;
}

export const GuessComposer: React.FC<GuessComposerProps> = ({ onChange }) => {
  const {
    puzzle,
    latestGuess,
    draftGuess,
    isOptionLocked,
    isSolved,
    initialOptionPositions,
  } = usePuzzleContext();
  const sortStrategy = useSortStrategy();

  const optionLineRef = React.useRef<HTMLDivElement>(null);
  const optionLineHeight = useRefHeight(optionLineRef);

  const [recentAnswerTrigger, setRecentAnswerTrigger] = React.useState(false);
  React.useEffect(() => {
    if (latestGuess) {
      setRecentAnswerTrigger(true);
      const timer = setTimeout(() => {
        setRecentAnswerTrigger(false);
      }, 700);
      return () => clearTimeout(timer);
    }
  }, [latestGuess]);

  const [activeDragOptionKey, setActiveDragOptionKey] =
    React.useState<OptionKey | null>(null);
  const activeDragOption = findBy(puzzle.options, 'key', activeDragOptionKey);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveDragOptionKey(event.active.id as OptionKey);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const newDraftGuess = sortStrategy.handleSort(
      draftGuess,
      active.id as OptionKey,
      over?.id as OptionKey | null
    );
    onChange(newDraftGuess);
    setActiveDragOptionKey(null);
  };

  const sortabledItems = React.useMemo(() => {
    return draftGuess.filter((optionKey) => !isOptionLocked(optionKey));
  }, [draftGuess, isOptionLocked]);

  const draftGuessOptions = draftGuess.map((optionKey) =>
    findByOrThrow(puzzle.options, 'key', optionKey)
  );

  return (
    <DndContext
      sensors={[useSensor(MouseSensor), useSensor(TouchSensor)]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {/*
         Note: SortableContext.items MUST be a stable reference. dnd-kit
         uses a referential equality check to determine if items have changed.
         This led to some weird transition bugs when I was creating a new array
         on each render (even if the array had the same contents). 
         https://github.com/clauderic/dnd-kit/blob/694dcc2f62e5269541fc941fa6c9af46ccd682ad/packages/sortable/src/hooks/useSortable.ts#L140
        */}
      <SortableContext
        items={sortabledItems}
        strategy={sortStrategy.dndkitStrategy}
      >
        <div className="ml-auto grid w-[99%] auto-rows-[1fr] gap-1 sm:w-full sm:gap-4">
          {draftGuessOptions.map((option) => {
            return (
              <OptionTile
                ref={optionLineRef}
                key={option.key}
                option={option}
                locked={isOptionLocked(option.key)}
                position={initialOptionPositions[option.key]}
                dimmed={isOptionLocked(option.key) && !isSolved()}
                shake={recentAnswerTrigger}
                showRevealDetails={isSolved()}
              />
            );
          })}
        </div>
      </SortableContext>
      <DragOverlay>
        {activeDragOption && (
          <OptionTileDummy
            option={activeDragOption}
            position={initialOptionPositions[activeDragOption.key]}
            height={optionLineHeight}
          />
        )}
      </DragOverlay>
    </DndContext>
  );
};
