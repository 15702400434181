import React from 'react';

import {
  getLatestGuess,
  gradeGuess,
  type Guess,
  isGuessCorrect,
  type Puzzle,
} from '../../../../puzzle/puzzle';

interface PuzzleContextValue {
  puzzle: Puzzle;
  submittedGuesses: Guess[];
  draftGuess: Guess;
  initialOptionPositions: Record<string, number>;
}

export const PuzzleContext = React.createContext<PuzzleContextValue | null>(
  null
);

export const usePuzzleContext = () => {
  const context = React.useContext(PuzzleContext);
  if (!context) {
    throw new Error('PuzzleContext must be used within a PuzzleProvider');
  }
  const { puzzle, submittedGuesses, draftGuess, initialOptionPositions } =
    context;

  const latestGuess = getLatestGuess(submittedGuesses);

  const lockedOptions = React.useMemo(() => {
    if (!latestGuess) {
      return [];
    }
    return gradeGuess(puzzle, latestGuess)
      .filter(([, isCorrect]) => isCorrect)
      .map(([optionKey]) => optionKey);
  }, [latestGuess]);

  return {
    puzzle,
    initialOptionPositions,
    latestGuess,
    draftGuess,
    isOptionLocked: React.useCallback(
      (optionKey: string) => {
        return lockedOptions.includes(optionKey);
      },
      [lockedOptions]
    ),
    isSolved: React.useCallback(() => {
      return Boolean(latestGuess && isGuessCorrect(puzzle, latestGuess));
    }, [latestGuess]),
  };
};
