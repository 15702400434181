import type React from 'react';

import { toTheDay } from '../../../lib/locale-date-strings';

interface PuzzleMetaProps {
  publishedAt: Date;
}

export const PuzzleMeta: React.FC<PuzzleMetaProps> = ({ publishedAt }) => {
  return (
    <div className="text-xs italic opacity-70">
      Published {toTheDay(publishedAt)}
    </div>
  );
};
