import * as Sentry from '@sentry/astro';

import type { Puzzle } from '../../puzzle/puzzle';

export const TrackEvents = {
  puzzleSolved: (puzzle: Puzzle, guessCount: number) => {
    fetch('/api/analytics/puzzle-solve', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        puzzle_id: puzzle.id,
        guess_count: guessCount,
      }),
    });
    Sentry.metrics.increment('puzzle_solved', 1, {
      tags: { puzzle_id: puzzle.id, guessCount: guessCount },
    });
  },
  resultCopied: (puzzle: Puzzle) => {
    Sentry.metrics.increment('result_copied', 1, {
      tags: { puzzle_id: puzzle.id },
    });
  },
  resultShareClicked: (puzzle: Puzzle) => {
    Sentry.metrics.increment('result_share_clicked', 1, {
      tags: { puzzle_id: puzzle.id },
    });
  },
  resultShared: (puzzle: Puzzle) => {
    Sentry.metrics.increment('result_shared', 1, {
      tags: { puzzle_id: puzzle.id },
    });
  },
};
