import { useQuery } from '@tanstack/react-query';
import React from 'react';

import {
  getOptionCount,
  getShortTitle,
  type GradedGuess,
  gradeGuess,
  type Guess,
  type Puzzle,
} from '../../../../puzzle/puzzle';
import shareIcon from '../../../assets/share-icon.svg';
import { TrackEvents } from '../../analytics';
import { APIClient } from '../../api-client';
import { isWindows } from '../../lib/mobile';
import { Button } from '../design/Button';
import { HorizontalLine } from '../design/HorizontalLine';
import { GuessesComparison } from './GuessesComparison';

const buildShareableGuesses = (
  puzzle: Puzzle,
  gradedGuesses: GradedGuess[]
) => {
  let res = '';
  for (
    let optionIndex = 0;
    optionIndex < getOptionCount(puzzle);
    optionIndex++
  ) {
    gradedGuesses.forEach((guess, guessIndex) => {
      if (guessIndex === 0) {
        res += NUMBER_EMOJI_MAP[String(optionIndex + 1)] + ' ';
      }
      const guessForOption = guess[optionIndex];
      res += guessForOption[1] ? CORRECT_GUESS_EMOJI : INCORRECT_GUESS_EMOJI;

      const isLastGuess = guessIndex === gradedGuesses.length - 1;
      if (!isLastGuess) {
        res += ' ';
      }
    });
    if (optionIndex !== getOptionCount(puzzle) - 1) {
      res += '\n';
    }
  }
  return res;
};
const NUMBER_EMOJI_MAP: Record<string, string> = {
  '1': '1️⃣',
  '2': '2️⃣',
  '3': '3️⃣',
  '4': '4️⃣',
  '5': '5️⃣',
  '6': '6️⃣',
  '7': '7️⃣',
  '8': '8️⃣',
  '9': '9️⃣',
};

const CORRECT_GUESS_EMOJI = '🟢';
const INCORRECT_GUESS_EMOJI = '🔴';

interface PuzzleResultsProps {
  puzzle: Puzzle;
  submittedGuesses: Guess[];
}
export const PuzzleResults: React.FC<PuzzleResultsProps> = ({
  puzzle,
  submittedGuesses,
}) => {
  const [resultsCopied, setResultsCopied] = React.useState(false);
  const gradedGuesses = submittedGuesses.map((guess) => {
    return gradeGuess(puzzle, guess);
  });

  const puzzleStatsQuery = useQuery({
    queryKey: ['puzzleStats', puzzle.id],
    queryFn: () => new APIClient().getPuzzleStats(puzzle.id),
  });

  const averageGuesses = React.useMemo<number | null>(() => {
    if (puzzleStatsQuery.data) {
      return puzzleStatsQuery.data?.average_guess_count;
    }
    return null;
  }, [puzzleStatsQuery.data]);

  const getCopyText = () => {
    return `I just played Disorderly! - ${getShortTitle(puzzle)} 
${location.href}

${buildShareableGuesses(puzzle, gradedGuesses)}`;
  };

  const handleCopyButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    navigator.clipboard.writeText(getCopyText());
    setResultsCopied(true);
    TrackEvents.resultCopied(puzzle);
  };

  const handleShareButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    try {
      TrackEvents.resultShareClicked(puzzle);
      await navigator.share({ text: getCopyText() });
      TrackEvents.resultShared(puzzle);
    } catch (e) {
      // The user cancelling a share shouldn't throw an error
      if (!(e instanceof Error && e.name === 'AbortError')) {
        throw e;
      }
    }
  };

  const button =
    typeof navigator.share === 'function' && !isWindows() ? (
      <Button className="text-lg" onClick={handleShareButtonClick}>
        Share <img className="w-5 text-white" src={shareIcon.src} />
      </Button>
    ) : (
      <Button className="text-lg" onClick={handleCopyButtonClick}>
        {resultsCopied ? 'Copied!' : 'Copy Results 📄'}
      </Button>
    );

  return (
    <div className="flex flex-col gap-4 rounded-lg bg-white bg-opacity-5 p-5 text-center">
      <div className="text-xl font-bold">
        You got the answer in {submittedGuesses.length}{' '}
        {submittedGuesses.length === 1 ? 'guess' : 'guesses'}!
      </div>
      <pre className="leading-[1.1]">
        {buildShareableGuesses(puzzle, gradedGuesses)}
      </pre>
      {button}
      {averageGuesses && (
        <>
          <HorizontalLine />
          <div className="mx-auto w-full max-w-48">
            <GuessesComparison
              user={submittedGuesses.length}
              average={averageGuesses}
            />
          </div>
        </>
      )}
    </div>
  );
};
