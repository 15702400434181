import confetti from 'canvas-confetti';
import React from 'react';

import type { Guess } from '../../../../puzzle/puzzle';
import {
  getCorrectAnswer,
  getOptionKeys,
  isGuessCorrect,
  type Puzzle,
} from '../../../../puzzle/puzzle';
import { DisorderlyTheme } from '../../../../theme/theme';
import { findBy, shuffleArray } from '../../../lib/array';
import { TrackEvents } from '../../analytics';
import { PuzzleResultStorage } from '../../storage/puzzle-result-storage';
import { ClientEntry } from '../ClientEntry';
import { Button } from '../design/Button';
import { ContentContainer } from '../design/ContentContainer';
import { KofiSocialButton } from '../design/SocialButton';
import { Tile } from '../design/Tile';
import { PuzzleResults } from '../puzzle-results/PuzzleResults';
import { GuessComposer } from './GuessComposer';
import { Marker } from './Marker';
import { PuzzleContext } from './PuzzleContext';
import { PuzzleMeta } from './PuzzleMeta';

interface PuzzlePlayerProps {
  puzzle: Puzzle;
  averageGuesses?: number | null;
}

export const PuzzlePlayer: React.FC<PuzzlePlayerProps> = ({ puzzle }) => {
  const [submittedGuesses, setSubmittedGuesses] = React.useState<Guess[]>(
    () => {
      return PuzzleResultStorage.get(puzzle)?.submittedGuesses ?? [];
    }
  );
  const [draftGuess, setDraftGuess] = React.useState<Guess>(() => {
    return submittedGuesses.length > 0
      ? submittedGuesses[submittedGuesses.length - 1]
      : shuffleArray(getOptionKeys(puzzle));
  });
  const [initialOptionPositions] = React.useState<Record<string, number>>(
    draftGuess.reduce(
      (acc, curr, i) => {
        acc[curr] = i;
        return acc;
      },
      {} as Record<string, number>
    )
  );

  const latestGuess = submittedGuesses[submittedGuesses.length - 1] ?? null;
  const isPuzzleSolved = latestGuess
    ? isGuessCorrect(puzzle, latestGuess)
    : false;

  React.useEffect(() => {
    if (isPuzzleSolved) {
      PuzzleResultStorage.set(puzzle, { submittedGuesses });
    }
  }, [isPuzzleSolved]);

  const isDraftGuessUnchanged = latestGuess
    ? latestGuess.every((optionKey, i) => optionKey === draftGuess[i])
    : false;

  const submitGuess = (guess: Guess, skipAnalytics = false) => {
    const newSubmittedGuesses = [...submittedGuesses, guess];
    setSubmittedGuesses(newSubmittedGuesses);
    setDraftGuess([...guess]);

    if (isGuessCorrect(puzzle, guess)) {
      const prefersReducedMotion = window.matchMedia(
        '(prefers-reduced-motion: reduce)'
      ).matches;

      const isSmallScreen = window.matchMedia('(max-width: 640px)').matches;
      if (!prefersReducedMotion) {
        confetti({
          colors: [...Object.values(DisorderlyTheme.colors.core)],
          scalar: 0.8,
          particleCount: 100,
          origin: { y: isSmallScreen ? 0.7 : 0.5 },
        });
      }
      if (!skipAnalytics) {
        TrackEvents.puzzleSolved(puzzle, newSubmittedGuesses.length);
      }
    }
  };

  const handleDraftGuessChange = (guess: Guess) => {
    setDraftGuess(guess);
  };

  const handleGuessSubmit = () => {
    submitGuess(draftGuess);
  };

  React.useEffect(() => {
    const cb = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 's') {
        submitGuess(getCorrectAnswer(puzzle), true);
      }
    };
    document.addEventListener('keydown', cb);
    return () => document.removeEventListener('keydown', cb);
  }, [submittedGuesses]);

  const funFact = findBy(puzzle.attributes ?? [], 'name', 'fun-fact')?.value;

  return (
    <ClientEntry>
      <PuzzleContext.Provider
        value={{ puzzle, submittedGuesses, draftGuess, initialOptionPositions }}
      >
        <ContentContainer className="p-0">
          <div className="px-2 sm:mb-8 sm:mt-4">
            <div className="mx-auto inline text-lg font-bold sm:text-2xl">
              {puzzle.title}
            </div>
            {puzzle.details && (
              <div className="text-subtle my-1 text-xs italic sm:text-base">
                {puzzle.details}
              </div>
            )}
          </div>
          {puzzle.markers && (
            <div className="mb-1">
              <Marker>{puzzle.markers[0]}</Marker>
            </div>
          )}
          <div>
            <GuessComposer onChange={handleDraftGuessChange} />
          </div>
          {puzzle.markers && (
            <div className="mt-1">
              <Marker>{puzzle.markers[1]}</Marker>
            </div>
          )}
          {!isPuzzleSolved && (
            <div className="w-full px-2">
              <Button
                className="mt-4 text-lg sm:mt-8"
                onClick={handleGuessSubmit}
                disabled={isDraftGuessUnchanged}
              >
                Submit Guess
              </Button>
            </div>
          )}
          <div className="mt-8 px-2 sm:px-3">
            {isPuzzleSolved && funFact && (
              <Tile className="mt-2" contentClassName="p-3" color="#38bdf8">
                <div className="text-xl font-bold">🥳 Just for fun</div>
                <div className="mt-2">{funFact}</div>
              </Tile>
            )}
            {isPuzzleSolved && (
              <div className="mt-4">
                <PuzzleResults
                  puzzle={puzzle}
                  submittedGuesses={submittedGuesses}
                />
              </div>
            )}
            {isPuzzleSolved && (puzzle.sources?.length ?? 0) > 0 && (
              <details className="text-subtle mt-2 flex flex-col gap-1 text-sm">
                <summary>Sources</summary>
                <ul>
                  {puzzle.sources?.map((source) => (
                    <li key={source}>
                      -{' '}
                      <a
                        href={source}
                        target="_blank"
                        className="text-subtle text-xs"
                      >
                        {source}
                      </a>
                    </li>
                  ))}
                </ul>
              </details>
            )}
            <div className="my-16">
              {isPuzzleSolved && (
                <div className="mt-8">
                  <KofiSocialButton />
                </div>
              )}
            </div>
            <div>
              <PuzzleMeta publishedAt={puzzle.publishedAt} />
            </div>
          </div>
        </ContentContainer>
      </PuzzleContext.Provider>
    </ClientEntry>
  );
};
